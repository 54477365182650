<template>
  <!-- todo: make plan ids, prices, coupon codes configurable -->
  <div>
    <div v-if="!fullscreenAd" class="SubscribeAd" :class="{ active: active, reverse: promo.reverse }">
      <div v-if="minimize" class="SubscribeAd-minimized">
        <span class="SubscribeAd-minimzedText">
          <span>{{ promo.minimizedText }}</span>
          <a v-if="!accountPreview" href="?login">Sign in</a>
          <a href="/bow/subscribe">Learn more</a>
        </span>

        <button @click="minimize = false">
          <CloIcon size="sm" symbol="arrowUp" />
          <span class="is-visuallyHidden">Open</span>
        </button>
      </div>

      <div
        v-else
        class="SubscribeAd-open"
        :style="
          'background-image: url(\'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/' +
          promo.imageUrl +
          '\')'
        "
      >
        <div class="SubscribeAd-header">
          <CloHeading
            level="2"
            size="1"
            :reverse="!promo.reverse"
            class="u-margin-none"
            >{{ promo.title }}</CloHeading
          >

          <button @click="hide">
            <CloIcon symbol="arrowDown" />
            <span class="is-visuallyHidden">Dismiss</span>
          </button>
        </div>

        <p
          v-if="promo.largeText.length"
          class="SubscribeAd-text--large"
          v-html="promo.largeText"
        ></p>

        <p
          v-if="promo.smallText.length"
          class="u-text-3 u-showForMedium u-margin-none"
          style="max-width: 60%"
          v-html="promo.smallText"
        ></p>

        <div>
          <a
            :id="promo.buttonId"
            :href="
              '/bow/subscribe' +
              (promo.couponCode ? `?couponCode=${promo.couponCode}` : '')
            "
            class="Button Button--large Button--subscribe"
            >{{ promo.buttonText }}</a
          >
        </div>

        <div class="SubscribeAd-credit">
          &copy;&nbsp;{{ promo.imageCredit }}; Cornell Lab | Macaulay Library
        </div>
      </div>
    </div>
    <!-- Full screen takeover -->
    <div v-else-if="active">
      <CloDialog id="fullscreenAd" @close="hide" class="Dialog--ad" v-if="!minimize">
        <template #body>
          <a :id="promo.buttonId" :href="'/bow/subscribe' + (promo.couponCode ? `?couponCode=${promo.couponCode}` : '')">
            <img :src="randomImage" alt="Subscribe to Birds of the World" />
          </a>
        </template>
      </CloDialog>
      <div v-else class="FullscreenAd-minimized">
        <span class="FullscreenAd-minimized-text">
          <span>{{ promo.minimizedText }}</span>
          <a v-if="!accountPreview" href="?login">Sign in</a>
          <a href="/bow/subscribe">Learn more</a>
        </span>

        <button @click="minimize = false">
          <CloIcon size="sm" symbol="arrowUp" />
          <span class="is-visuallyHidden">Open</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CloHeading from "@/base/CloHeading/CloHeading.vue";
import CloIcon from "@/icons/CloIcon/CloIcon.vue";
import CloDialog from "@/base/CloDialog/CloDialog.vue";
export default {
  components: {
    CloHeading,
    CloIcon,
    CloDialog
  },
  data() {
    return {
      active: false,
      minimize: false,
      delay: 5, // 5 seconds
      images: {
        gbd: [],
        obd: [],
        gbbc: [],
        default: [
          'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-White-Tern.png',
          'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Laughingthrush.png',
          'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Honey-Buzzard.png',
          'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Plover.png',
          'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Puffin.png',
          'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Western-Gull.png',
          'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Buffy-Fish-Owl.png'
        ],
      }
    };
  },
  props: {
    campaignId: {
      type: String,
      default: "warbler", // warbler, lark, gbd
    },
    accountPreview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    randomImage() {
      // TODO: change when GBBC 24 is over
      //const images = this.images[this.campaignId];
      const images = this.images.default;
      return images[Math.floor(Math.random() * images.length)];
    },
    fullscreenAd() {
      // TODO: remove when GBBC 24 is over
      return this.campaignId != "gbbc"
    },
    promo() {
      let promo = {
        title: "Discover all the Birds of the World",
        largeText:
          "<strong>Definitive life histories of every bird species</strong>",
        smallText: `<span class="SubscribeAd-text--italic">Birds of the World</span> is the
        world's leading scholarly ornithological resource.`,
        imageUrl: "4/2022/04/bwwa.jpg",
        imageCredit: "Matt Mason",
        buttonText: "Learn more",
        buttonId: "bow_ad_account_default",
        couponCode: 'TRYMENOW',
        minimizedText:
          "This content is available exclusively to Birds of the World Subscribers.",
        reverse: false,
      };
      if (this.campaignId === "gbbc") {
        promo.title = "Subscribe today!";
        promo.largeText = "Save 25% on first billing cycle (any plan!) with coupon code GBBC24";
        promo.smallText = `Valid until 2/20/2024.`;
        promo.minimizedText = "Enjoy complimentary access for the Great Backyard Bird Count.";
        promo.imageUrl = "4/2022/09/pitta2.jpg";
        promo.imageCredit = "Natthaphat Chotjuckdikul";
        promo.buttonText = "Learn more";
        promo.couponCode = "GBBC24";
        promo.buttonId = "bow_ad_account_special";
      }
      if (this.campaignId === "gbd") {
        /*
        promo.title = 'Celebrate Global Big Day!'
        promo.minimizedText = 'Enjoy complimentary access for Global Big Day.'
        promo.imageUrl = '4/2022/09/ibis.jpg'
        promo.imageCredit = 'Hal and Kirsten Snyder'
        promo.buttonText = 'Save 30% today'
        promo.couponCode = 'GLOBALBIGDAY2023'
        promo.buttonId = 'bow_ad_account_special'
        */
				promo.title = 'Celebrate Birds on October Big Day!';
				promo.largeText = '<strong>With a 30% discount on <i>Birds of the World</i></strong>';
				promo.smallText = `Use coupon OCTOBERBIGDAY ($42 USD) to save big on the world's leading ornithological resource. (First term only)`;
				promo.minimizedText = 'Enjoy complimentary access for October Big Day.';
				promo.imageUrl = '4/2023/09/376162321_edit.jpg';
				promo.imageCredit = 'Rajkumar Das';
				promo.buttonText = 'Learn more';
				promo.couponCode = 'OCTOBERBIGDAY';
				promo.buttonId = 'bow_ad_account_special';
			}
			if (this.accountPreview) {
				promo.minimizedText = 'Enjoy complimentary access to this Birds of the World species account.';
			}
			if (this.campaignId === 'warbler') {
				promo.imageUrl = '4/2022/04/bwwa.jpg';
				promo.imageCredit = 'Matt Mason';
			}
			if (this.campaignId === 'lark') {
				promo.imageUrl = '4/2022/04/lark3.jpg';
				promo.imageCredit = 'Daniel Pettersson';
			}
			if (this.campaignId === 'loon') {
				promo.imageUrl = '4/2022/09/loon2.jpg';
				promo.imageCredit = 'Matthew Bode';
			}
			if (this.campaignId === 'pitta') {
				promo.imageUrl = '4/2022/09/pitta2.jpg';
				promo.imageCredit = 'Natthaphat Chotjuckdikul';
			}
			if (this.campaignId === 'ibis') {
				promo.imageUrl = '4/2022/09/ibis.jpg';
				promo.imageCredit = 'Hal and Kirsten Snyder';
			}
			if (this.campaignId === 'chlorophonia') {
				promo.imageUrl = '4/2022/04/blue-naped-chlorophonia.jpg';
				promo.imageCredit = 'Phil Chaon';
			}
			if (this.campaignId === 'novemberGift') {
				promo.imageUrl = '4/2022/10/burrowing_owl3.jpg'; // burrowing owl
				promo.imageCredit = 'Grigory Heaton';
				promo.title = 'Give the gift of Birds!';
				promo.largeText =
					'A Birds of the World subscription opens a window to the entire world of birds.<br>Save 15% with coupon code GIFTOFBIRDS15 ($42 USD).';
				promo.smallText = '';
				promo.couponCode = 'GIFTOFBIRDS15';
				promo.buttonId = 'bow_ad_account_special';
				promo.reverse = true;
			}
			if (this.campaignId === 'decemberGift') {
				promo.imageUrl = '4/2022/10/green_ibis2.jpg'; // scarlet ibis
				promo.imageCredit = 'Claudia Brasileiro';
				promo.largeText =
					'A Birds of the World subscription opens a window to the entire world of birds.<br>Save 15% with coupon code GIFTOFBIRDS15 ($42 USD).';
				promo.smallText = '';
				promo.title = 'Give the gift of Birds!';
				promo.couponCode = 'GIFTOFBIRDS15';
				promo.buttonId = 'bow_ad_account_special';
			}
			return promo;
		},
	},
	mounted() {
		// show minimized if cookie is valid
		if (document.cookie.split('; ').find((row) => row.startsWith('bowSubscribeAd'))) {
			this.minimize = true;
		}
		// animate in after delay
		setTimeout(() => {
			this.active = true;
		}, this.delay * 1000);
	},
	methods: {
		hide() {
			this.minimize = true;
			let date = new Date();
			date.setTime(date.getTime() + 1000 * 60 * 30); // 30 minutes from now
			date = date.toUTCString();
			document.cookie = `bowSubscribeAd=; expires=${date}; path=/; SameSite=None; Secure`;
		},
	},
};
</script>

<style lang="scss">
.SubscribeAd {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	color: $color-white;
	background-color: $color-neutral-5;
	box-shadow: -2px -2px 4px rgba(32, 32, 32, 0.25);
	transition: all 0.3s ease;
	transform: translate(0, 100%);
	@include breakpoint(medium) {
		left: auto;
		transform: translate(100%, 0);
	}
	opacity: 0;
	&.active {
		transform: translate(0, 0);
		opacity: 1;
	}
	&.reverse {
		color: $color-text;
	}
	&-minimized {
		@include text(2);
		@include inset-squish-md;
		@include colorReverse;
		color: $color-white;
		background-color: $color-primary-5;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: $space-md;
	}
	&-minimzedText {
		display: flex;
		gap: $space-sm $space-md;
		flex-wrap: wrap;
	}
	&-open {
		min-height: 40vh;
		max-height: 50vh;
		overflow-y: auto;
		@include inset-lg;
		background-position: bottom right;
		background-repeat: no-repeat;
		background-size: 170%;
		@media (min-width: 450px) {
			background-size: 125%;
			min-height: 250px;
		}
		@include breakpoint(medium) {
			background-size: 105%;
			min-height: 300px;
		}
		display: flex;
		flex-direction: column;
		gap: $space-md;
	}
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: $space-lg;
	}
	&-credit {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		color: $color-white;
		background-color: rgba(0, 0, 0, 0.5);
		@include text(1);
		@include inset-squish-sm;
		text-align: right;
	}
	&-text--italic {
		font-style: italic;
	}
	&-text--large {
		@include text(3);
		margin: 0;
		max-width: 50%;
		@include breakpoint(medium) {
			@include text(3);
			max-width: 75%;
		}
		@include breakpoint(large) {
			max-width: 80%;
		}
	}
}
.Dialog--ad {
  .Dialog-container {
    outline: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    position: relative;
    max-width: calc(100vh - 0.5rem);
    max-height: calc(100vw - 0.5rem);
    @include breakpoint(xlarge) {
      max-width: calc(100vh - 3rem);
      max-height: calc(100vw - 3rem);
    }
  }
  .Dialog-container-close {
	top: -$space-md;
	@include breakpoint(medium) {
		right: $space-sm;
	}
    .Icon {
      fill: $color-white;
    }
  }
  .Dialog-container-body {
    padding: 2rem 1rem;
	@include breakpoint(medium) {
	  padding: 2rem;
	}
    overflow-y: visible;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    }
  }
}
.FullscreenAd-minimized {
  z-index: 1001;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  @include inset-md;
  @include text(3);
  @include colorReverse;
  color: $color-white;
  background-color: $color-primary-5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-md;
}
.FullscreenAd-minimized-text {
  display: flex;
  gap: $space-sm $space-md;
  flex-wrap: wrap;
}
</style>
